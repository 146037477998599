import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const PaymentForm = ({
  getCartCount,
  setShowCart,
  setShippingData,
  setSelectedShipping,
  handleShow,
  selectedShpping,
  formData,
  setFormData,
  selectedOption,
  setSelectedOption,
}) => {
  const [customError, setCustomError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const randomUserIdGuest = sessionStorage.getItem("randomUserId")
  const countryCodes = [
    { label: "Afghanistan", value: "AF" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, The Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Cote D'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, Democratic People's Republic of", value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People's Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "North Macedonia", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "Netherlands Antilles", value: "AN" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestinian Territory, Occupied", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia and Montenegro", value: "CS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Surilabel", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" }
  ];
  const [isProcessing, setIsProcessing] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const ref = localStorage.getItem('ref')

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (name === 'card_number') {
      const cardNumber = value.replace(/\D/g, '');
      if (value.length <= 19) {
        setFormData({
          ...formData,
          [name]: cardNumber,
        });
      }
    }
    else if (name === 'expiry_date') {
      const expirationDate = value.replace(/\D/g, '');
      if (value.length <= 5) {
        setFormData({
          ...formData,
          [name]: expirationDate,
        });
      }
    } else if (name === 'cvc') {
      const cvc = value.replace(/\D/g, '');
      if (value.length < 5) {
        setFormData({
          ...formData,
          [name]: cvc,
        });
      }
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true)
    setCustomError(true)
    console.log(formData, 'formData');
    if (formData.cvc.length < 3) {
      setIsProcessing(false)
      console.log(formData, 'formData 1');
      return
    }
    if (formData.card_number.length < 16) {
      setIsProcessing(false)
      console.log(formData, 'formData 2');
      return
    }

    const expiryMonth = parseInt(formData.expiry_date.slice(0, 2), 10);
    const expiryYear = parseInt('20' + formData.expiry_date.slice(2, 4), 10); // assuming '22' represents 2022, adjust if necessary

    if (expiryYear > year || // Expiry year is greater than current year
      (expiryYear === year && expiryMonth > month) || // Expiry year is current year, but expiry month is greater
      (expiryYear === year && expiryMonth === month && day > 1)) {
      console.log(formData, 'formData 3');
      if (formData.name != '' &&
        formData.companey_name != '' &&
        formData.town != '' &&
        formData.phone_number != '' &&
        formData.email != '' &&
        selectedOption != null &&
        formData.state != '' &&
        formData.postal != ''
      ) {
        const token = sessionStorage.getItem("userToken");
        try {
          const response = await axios.post(
            'https://max88backend.testingwebsitelink.com/api/buynow/order',
            {
              name: formData.name,
              last_name: formData.companey_name,
              street_address: formData.street_address,
              aparment: formData.aparment,
              town: formData.town,
              phone_number: formData.phone_number,
              email: formData.email,
              zip_code: formData.postal,
              country: selectedOption.label,
              card_number: formData?.card_number,
              cvv: formData?.cvc,
              expiry_date: formData?.expiry_date,
              gest_user_id: randomUserIdGuest,
              state: formData?.state,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success('Payment successful');
        //   setShowCart(false)
        //   localStorage.removeItem('ref')
          navigate("/oder-success");
          setIsProcessing(false)
          setLoading(false);
        } catch (error) {
          handleShow(error?.response?.data?.message);
          setIsProcessing(false)
          setLoading(false);
        }
        setIsProcessing(false)
      }
    } else {
      setIsProcessing(false)
    }
    setIsProcessing(false)

  };



  const onchnageCountry = (e) => {
    setSelectedOption(e)
  }

  function formatCardNumber(input) {
    let formatted = input.replace(/\s+/g, '');
    formatted = formatted.replace(/(.{4})/g, '$1 ');
    formatted = formatted.trim();
    return formatted;
  }

  function formatExpirationDate(input) {
    let formatted = input.replace(/\s+/g, '');
    formatted = formatted.replace(/(\d{2})(?=\d)/g, '$1/');
    return formatted;
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className="my-3 checkout-input-f"
          controlId="formBasicFirstName"
        >
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            name='name'
            value={formData.name}
            onChange={handleChange} />
          {(formData.name === '' && customError) &&
            <p className="input-error-show">First name is Required</p>
          }
        </Form.Group>
        <Form.Group
          className="my-3 checkout-input-f"
          controlId="formBasicCompanyName"
        >
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            name='companey_name'
            value={formData.companey_name}
            onChange={handleChange}
          />
          {(formData.companey_name === '' && customError) &&
            <p className="input-error-show">Last name is Required</p>
          }
        </Form.Group>
        <Form.Group className="my-3 checkout-input-f">
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type="text"
            name='street_address'
            value={formData.street_address}
            onChange={handleChange}
          />
          {(formData.street_address === '' && customError) &&
            <p className="input-error-show">Street address is Required</p>
          }
        </Form.Group>
        <Form.Group className="my-3 checkout-input-f">
          <Form.Label>Apartment, floor, etc. (optional)</Form.Label>
          <Form.Control
            type="text"
            name='aparment'
            value={formData.aparment}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3 checkout-input-f">
          <Form.Label>Country</Form.Label>
          <Select
            defaultValue={selectedOption}
            onChange={onchnageCountry}
            options={countryCodes}
          />
          {(selectedOption === null && customError) &&
            <p className="input-error-show">Country is Required</p>
          }
        </Form.Group>
        <Form.Group className="mb-3 checkout-input-f">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            name='postal'
            value={formData.postal}
            onChange={handleChange}
          />
          {(formData.postal === '' && customError) &&
            <p className="input-error-show">Postal code is Required</p>
          }
        </Form.Group>
        <Form.Group className="mb-3 checkout-input-f">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            name='state'
            value={formData.state}
            onChange={handleChange}
          />
          {(formData.state === '' && customError) &&
            <p className="input-error-show">State is Required</p>
          }
        </Form.Group>
        <Form.Group className="mb-3 checkout-input-f">
          <Form.Label>Town/City</Form.Label>
          <Form.Control
            type="text"
            name='town'
            value={formData.town}
            onChange={handleChange}
          />
          {(formData.town === '' && customError) &&
            <p className="input-error-show">Town/City is Required</p>
          }
        </Form.Group>
        <Form.Group className="my-3 checkout-input-f">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            name='phone_number'
            value={formData.phone_number}
            onChange={handleChange}
          />
          {(formData.phone_number === '' && customError) &&
            <p className="input-error-show">Phone number is Required</p>
          }
        </Form.Group>
        <Form.Group className="my-3 checkout-input-f" controlId="">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            name='email'
            value={formData.email}
            onChange={handleChange}
          />
          {(formData.email === '' && customError) &&
            <p className="input-error-show">Email is Required</p>
          }
        </Form.Group>
        <Form.Group className="my-3 checkout-input-f" controlId="">
          <Form.Label>Card number</Form.Label>
          <Form.Control
            type="text"
            name='card_number'
            placeholder="XXXX XXXX XXXX XXXX"
            value={formatCardNumber(formData.card_number)}
            onChange={handleChange}
          />
          {(formData.card_number === '' && customError) &&
            <p className="input-error-show">Card number is Required</p>
          }
          {(formData.card_number.length < 16 && customError && formData.card_number != '') &&
            <p className="input-error-show">Your card number is incomplete.</p>
          }
        </Form.Group>
        <div className="date-csv" style={{
          display: 'flex',
          width: '100%',
          gap: '10px'
        }}>
          <Form.Group className="my-3 checkout-input-f" controlId="" style={{ flex: 1 }}>
            <Form.Label>Expiration </Form.Label>
            <Form.Control
              type="text"
              name='expiry_date'
              placeholder="11/22"
              value={formatExpirationDate(formData.expiry_date)}
              onChange={handleChange}
            />
            {(formData.expiry_date === '' && customError) &&
              <p className="input-error-show">Expiration is Required</p>
            }
            {(
              formData.expiry_date !== '' &&
              (
                year > parseInt('20' + formData.expiry_date.slice(2, 4), 10) ||
                (year === parseInt('20' + formData.expiry_date.slice(2, 4), 10) &&
                  month > parseInt(formData.expiry_date.slice(0, 2), 10)) ||
                (year === parseInt('20' + formData.expiry_date.slice(2, 4), 10) &&
                  month === parseInt(formData.expiry_date.slice(0, 2), 10)
                )
              )

            ) &&
              <p className="input-error-show">Your card's expiration date is in the past.</p>
            }
          </Form.Group>
          <Form.Group className="my-3 checkout-input-f" controlId="" style={{ flex: 1 }}>
            <Form.Label>CVV</Form.Label>
            <Form.Control
              type="number"
              name='cvc'
              placeholder="CVC"
              value={formData.cvc}
              onChange={handleChange}
            />
            {(formData.cvc === '' && customError) &&
              <p className="input-error-show">CVC number is Required</p>
            }
            {(formData.cvc.length < 3 && customError && formData.cvc != '') &&
              <p className="input-error-show">Your card's security code is incomplete.</p>
            }
          </Form.Group>
        </div>
        <Form.Group
          className="my-3 checkout-check-bx"
          controlId="formBasicCheckbox"
        >
          <Form.Check
            type="checkbox"
            label="Save this information for faster check-out next time"
          />
        </Form.Group>

        <div className="cart-button-btn-cpn cart-button-btn-div  cart-button-btn-div01">
          <button className='proceedCheckout3 mt-2' type="submit" disabled={isProcessing}>
            {isProcessing ? 'Loading...' : 'Pay Now'}
          </button>
        </div>
        {error && <div>{error}</div>}
      </Form>
    </>
  );
};
const BuyItNowCheckout = ({ getCartCount, setShowCart }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [products, setProducts] = useState([]);
  const [showShiping, setShowShiping] = useState(false);
  const [shppingData, setShippingData] = useState([]);
  const [selectedShpping, setSelectedShipping] = useState([]);
  const [price, setPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize isLoading state as true
  const randomUserIdGuest = sessionStorage.getItem("randomUserId")
  const referral_code = sessionStorage.getItem("referral_code");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    companey_name: "",
    street_address: "",
    aparment: "",
    town: "",
    phone_number: "",
    email: "",
    postal: "",
    state: "",
    card_number: '',
    cvc: '',
    expiry_date: ''
  });
  const [fullScreenLoader, setFullScreenLoader] = useState(false)
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.get(
        `https://max88backend.testingwebsitelink.com/api/buynow/list?user_id=${randomUserIdGuest}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      setProducts(response.data.data.cart);
      setPrice(response.data.data);
    } catch (error) {
      setIsLoading(false);
      handleShow(error?.response?.data?.message);
    }
  };
  useEffect(() => {

    fetchData();
  }, []);

  const [clientSecret, setClientSecret] = useState('')

  return (
    <>
      {fullScreenLoader ?
        <div className="loader-container-full-screen text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <div className="container cart-sec">
          <div className="mt-4 checkout-div">
            <h3>Checkout</h3>
          </div>
          <div className="mt-4 checkout-div">
            <h3>Billing Details</h3>
          </div>
          <div className="row mt-4 checkout-detail-con">
            <div className="col-lg-6">
              {/* {stripePromise && clientSecret &&
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}> */}
              <PaymentForm
                getCartCount={getCartCount}
                setShowCart={setShowCart}
                setShippingData={setShippingData}
                setShowPopup={setShowPopup}
                setErrorMessage={setErrorMessage}
                handleShow={handleShow}
                selectedShpping={selectedShpping}
                setSelectedShipping={setSelectedShipping}
                finalPrice={+price?.discountPrice}
                setClientSecret={setClientSecret}
                formData={formData}
                setFormData={setFormData}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setFullScreenLoader={setFullScreenLoader}
              />
              {/* </Elements>
              } */}
            </div>
            <div className="col-lg-6">
              {isLoading ? (
                <div className="col-lg-9">
                  <div className="loader-container text-center" style={{ height: '200px' }}>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              ) : (
                <>
                  {products?.length === 0 ? (
                    <div className="col-lg-9"><h2
                      style={{
                        height: '150px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                      }}
                    >No orders available</h2></div>
                  ) : (
                    <>
                      {products.map((item, index) => (
                        <div
                          className="col-lg-9 mt-3 category-right-sec"
                          key={index}
                        >
                          {/* {item.product.map((product, prodIndex) => ( */}
                            <div className="category-right-sec-f">
                              <div
                                style={{
                                  display: "flex",
                                  width: "70%",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div style={{ width: "70px", textAlign: "left" }}>
                                  <img
                                    className="product-det-img"
                                    height={70}
                                    width={70}
                                    src={item?.ticket?.image?.[0]}
                                  />
                                </div>
                                <p>{item?.ticket?.title} x {item?.quantity}</p>
                              </div>
                              <div>

                                <p>$ {((+item?.ticket?.price) * (+item?.quantity))?.toFixed(2)}</p>
                              </div>
                            </div>
                          {/* ))} */}
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}


              <div className="col-lg-9 mt-3 category-right-sec category-right-sec-sh1">
                <div className="category-right-sec-sh">
                  <div>
                    <p>Sales Tax</p>
                  </div>
                </div>
                <div>
                  <div><p>{(+price?.tax)?.toFixed(2)}</p></div>
                </div>
              </div>

              <div className="col-lg-9 mt-3 category-right-sec">
                <div className="">
                  <div>
                    <p>Total</p>
                  </div>
                </div>
                <div>
                    <p>$ {(+price?.finalPrice) + (+price?.tax)}</p>
                </div>
              </div>
              {/* <Form className="">
              <div className="mb-3 radio-checkout-btn">
                <Form.Check
                  type="radio"
                  id="radio-option1"
                  label="Bank"
                  name="radioGroup"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleRadioChange}
                />
              </div>
            </Form> */}
            </div>
          </div>
        </div>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyItNowCheckout;
