import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FaCartShopping } from "react-icons/fa6";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";

const Navigation = ({ cartCount, showCart, setShowCart, products, setCartCount }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const firstName = sessionStorage.getItem("firstName");
  const userId = sessionStorage.getItem("userId");
  const role = sessionStorage.getItem("role");
  const paymentStatus = sessionStorage.getItem("paymentStatus");
  const [show, setShow] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false)
  const [currentPathRouth, setCurrentPathRouth] = useState('')
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const packageId = params.get('id');

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const signOut = (e) => {
    e.preventDefault();
    sessionStorage.clear()
    window.location.href = "/";
  };
  const generateUserId = () => {
    const randomUserId = Math.floor(Math.random() * 1000000);
    sessionStorage.setItem("randomUserId", randomUserId);
    return randomUserId;
  };
  const randomUserIdGuest = sessionStorage.getItem("randomUserId") || generateUserId();
  const handleRemoveCart = async () => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/gest_clear_cart",
        {
          gest_user_id: randomUserIdGuest
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.href = currentPathRouth;
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleRemoveCartUser = async () => {
    try {
      const token = sessionStorage.getItem("userToken");

      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/clear_cart",
        {
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.href = currentPathRouth;
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const deleteGestUser = async (currentPathRouth) => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.get(
        `https://max88backend.testingwebsitelink.com/api/deleteUser/${userId}`
      );
      sessionStorage.clear()
      navigate(currentPathRouth);
      setDeleteUser(false)
      setShowCart(false)
      setCartCount(0)
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="header-wrap">
            <div className="left">
              <div className="logo">
                {currentPath.includes('selectmaxpack') ||
                  currentPath.includes('member-shop') ||
                  currentPath.includes('member-cart') ||
                  currentPath.includes('joinasamember') ||
                  packageId
                  ?
                  <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/') }}>
                    <div className="logo-wrap">
                      <img
                        width={60}
                        src={require("../assets/logo/f-logo.png")}
                        alt="MAX88-logo"
                      />
                      <div>
                        <p>MAXIMUM 88</p>
                        <p>CORPORATION</p>
                      </div>
                    </div>
                  </a>
                  :
                  (paymentStatus === "0" ?
                    <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/') }}>
                      <div className="logo-wrap">
                        <img
                          width={60}
                          src={require("../assets/logo/f-logo.png")}
                          alt="MAX88-logo"
                        />
                        <div>
                          <p>MAXIMUM 88</p>
                          <p>CORPORATION</p>
                        </div>
                      </div>
                    </a>
                    :
                    <Link to={'/'}>
                      <div className="logo-wrap">
                        <img
                          width={60}
                          src={require("../assets/logo/f-logo.png")}
                          alt="MAX88-logo"
                        />
                        <div>
                          <p>MAXIMUM 88</p>
                          <p>CORPORATION</p>
                        </div>
                      </div>
                    </Link>

                  )
                }
              </div>
            </div>
            <div className="right" id="hide-on-mobile">
              <ul>
                {currentPath.includes('selectmaxpack') ||
                  currentPath.includes('member-shop') ||
                  currentPath.includes('member-cart') ||
                  currentPath.includes('joinasamember') ||
                  packageId
                  ?
                  <>
                    <li>
                      <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/') }} className={` ${currentPath === "/" ? "active" : ""}`}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/shop') }} className={` ${currentPath === "/shop" ? "active" : ""}`}>
                        Shop
                      </a>
                    </li>
                    <li>
                      <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/ticket') }} className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                      Ticket
                      </a>
                    </li>
                    <li>
                      <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/about') }} className={` ${currentPath === "/about" ? "active" : ""}`}>
                        About Us
                      </a>
                    </li>
                  </>
                  :
                  (paymentStatus === "0" ?
                    <>
                      <li>
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/') }} className={` ${currentPath === "/" ? "active" : ""}`}>
                          Home
                        </a>
                      </li>
                      <li>
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/shop') }} className={` ${currentPath === "/shop" ? "active" : ""}`}>
                          Shop
                        </a>
                      </li>
                      <li>
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/ticket') }} className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                        Ticket
                        </a>
                      </li>
                      <li>
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/about') }} className={` ${currentPath === "/about" ? "active" : ""}`}>
                          About Us
                        </a>
                      </li>
                    </>
                    :
                    <>
                      <li>
                        <Link to="/" className={` ${currentPath === "/" ? "active" : ""}`}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop" className={` ${currentPath === "/shop" ? "active" : ""}`}>
                          Shop
                        </Link>
                      </li>
                      <li>
                        <Link to="/ticket" className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                          Ticket
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className={` ${currentPath === "/about" ? "active" : ""}`}>
                          About Us
                        </Link>
                      </li>
                    </>
                  )
                }
                {(userId === null || paymentStatus === "0") ? null :
                  (
                    role === 'member' ?
                      (packageId ?
                        <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/dashboard-member'); setShow(false) }}>
                          <a className={` ${currentPath === "/dashboard-member" ? "active" : ""}`}>
                            Dashboard
                          </a>
                        </li>
                        :
                        <li>
                          <Link to="/dashboard-member" className={` ${currentPath === "/dashboard-member" ? "active" : ""}`}>
                            Dashboard
                          </Link>
                        </li>
                      )
                      :
                      <li>
                        <Link to="/dashboard" className={` ${currentPath === "/dashboard" ? "active" : ""}`}>
                          Dashboard
                        </Link>
                      </li>
                  )
                }
                {currentPath.includes('selectmaxpack') ||
                  currentPath.includes('member-shop') ||
                  currentPath.includes('member-cart') ||
                  currentPath.includes('joinasamember') ||
                  packageId
                  ? null :
                  (paymentStatus === '0' ?
                    null
                    :
                    (<li>
                      <div className="header-cart">
                        <Link
                          to={userId === null ? "/guest-cart" : '/cart'}>
                          <FaCartShopping />
                          {cartCount > 0 && <span>{cartCount}</span>}
                        </Link>
                        {showCart &&
                          (currentPath === "/guest-cart" || currentPath === "/cart" ? null :
                            <div className="header-cart-last-product">
                              <div className="header-cart-last-product-wrap">
                                <span className="close" onClick={() => { setShowCart(false) }}>X</span>
                                <p className="Successfully">Successfully added item(s) to cart</p>
                                <div className="img-price">
                                  <div className="img-price-left">
                                    <img src={products?.[products?.length - 1]?.product?.[0]?.main_image_new} alt="" />
                                  </div>
                                  <div className="img-price-right">
                                    <div className="title">{products?.[products?.length - 1]?.product?.[0]?.tittle}</div>

                                    {role === 'member' &&
                                      <div className="price">
                                        <div className="line-true">${products?.[products?.length - 1]?.product?.[0]?.price}</div>
                                        <div> ${products?.[products?.length - 1]?.product?.[0]?.member_price}</div>
                                      </div>
                                    }
                                    {role === 'costomer' &&
                                      <div className="price">
                                        <div className="line-true">${products?.[products?.length - 1]?.product?.[0]?.price}</div>
                                        <div> ${products?.[products?.length - 1]?.product?.[0]?.customer_price}</div>
                                      </div>
                                    }
                                    {(role != 'costomer' && role != 'member') &&
                                      <div className="price">${products?.[products?.length - 1]?.product?.[0]?.price}</div>
                                    }

                                  </div>
                                </div>
                                <div className="total">
                                  <Link to={userId === null ? "/guest-cart" : '/cart'} onClick={() => { setShowCart(false) }}>
                                    <p>View Cart ({cartCount})</p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </li>)

                  )
                }
                <li>
                  {userId === null ? (
                    <div className="buttons">
                      {cartCount > 0 ?
                        <button onClick={() => { setShowRemove(true); setCurrentPathRouth('/Joinus') }} className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                        :
                        <Link to="/Joinus">
                          <button className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                        </Link>
                      }
                      <Link to="/login">
                        <button className={`header-login-btn ${currentPath === "signup" ? "active-text" : ""}`}>LOG IN</button>
                      </Link>
                    </div>
                  ) : (
                    paymentStatus === "0" ?
                      <div className="buttons">
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/Joinus') }}>
                          <button className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                        </a>
                        <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/login') }}>
                          <button className={`header-login-btn ${currentPath === "signup" ? "active-text" : ""}`}>LOG IN</button>
                        </a>
                      </div>
                      :
                      <DropdownButton
                        className={` ${currentPath === "joinus" ? "active-text" : ""} btn-danger joinButton1`}
                        size="lg" id="dropdown-basic-button" title={firstName ? firstName : "JOIN US"}>
                        <Dropdown.Item className="joinButton1-item" onClick={signOut}>
                          Log out
                        </Dropdown.Item>
                      </DropdownButton>
                  )}
                </li>
              </ul>
            </div>
            <div className="hamburger-cart" id="hide-on-desktop">
              {currentPath.includes('selectmaxpack') ||
                currentPath.includes('member-shop') ||
                currentPath.includes('member-cart') ||
                currentPath.includes('joinasamember') ||
                packageId ||
                paymentStatus === "0"
                ? null :
                <Link
                  to={userId === null ? "/guest-cart" : '/cart'}
                  className={` ${currentPath === "guest-cart" || "cart" ? "active" : ""}`}>
                  <div className="header-cart">
                    <FaCartShopping />
                    {cartCount > 0 && <span>{cartCount}</span>}
                  </div>
                </Link>
              }
              <div className="toggle-btn" onClick={() => { setShow(!show) }}>
                <GiHamburgerMenu />
              </div>
            </div>
          </div>
          <div className={`mobile-nav ${show ? 'show' : ''}`} id="hide-on-desktop">
            <div className="mobile-nav-wrap">
              <div className="top">
                {currentPath.includes('selectmaxpack') ||
                  currentPath.includes('member-shop') ||
                  currentPath.includes('member-cart') ||
                  currentPath.includes('joinasamember') ||
                  packageId
                  ?
                  <a onClick={() => { setShowRemove(true); setCurrentPathRouth('/'); setShow(false) }}>
                    <div className="logo">
                      <div className="logo-wrap">
                        <img
                          width={60}
                          src={require("../assets/logo/f-logo.png")}
                          alt="MAX88-logo"
                        />
                        <div>
                          <p>MAXIMUM 88</p>
                          <p>CORPORATION</p>
                        </div>
                      </div>
                    </div>
                  </a>
                  :
                  (paymentStatus === "0" ?
                    <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/'); setShow(false) }}>
                      <div className="logo">
                        <div className="logo-wrap">
                          <img
                            width={60}
                            src={require("../assets/logo/f-logo.png")}
                            alt="MAX88-logo"
                          />
                          <div>
                            <p>MAXIMUM 88</p>
                            <p>CORPORATION</p>
                          </div>
                        </div>
                      </div>
                    </a>
                    :
                    <Link to={'/'} onClick={() => { setShow(false) }}>
                      <div className="logo">
                        <div className="logo-wrap">
                          <img
                            width={60}
                            src={require("../assets/logo/f-logo.png")}
                            alt="MAX88-logo"
                          />
                          <div>
                            <p>MAXIMUM 88</p>
                            <p>CORPORATION</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                }
                <span onClick={() => { setShow(false) }}>x</span>
              </div>

              <div className="bottom">
                <ul>
                  {currentPath.includes('selectmaxpack') ||
                    currentPath.includes('member-shop') ||
                    currentPath.includes('member-cart') ||
                    currentPath.includes('joinasamember') ||
                    packageId
                    ?
                    <>
                      <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/'); setShow(false) }}>
                        <a className={` ${currentPath === "/" ? "active" : ""}`}>
                          Home
                        </a>
                      </li>
                      <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/shop'); setShow(false) }}>
                        <a className={` ${currentPath === "/shop" ? "active" : ""}`}>
                          Shop
                        </a>
                      </li>
                      <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/ticket'); setShow(false) }}>
                        <a className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                          Ticket
                        </a>
                      </li>
                      <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/about'); setShow(false) }}>
                        <a className={` ${currentPath === "/about" ? "active" : ""}`}>
                          About Us
                        </a>
                      </li>
                    </>
                    :
                    (paymentStatus === "0" ?
                      <>
                        <li onClick={() => { setDeleteUser(true); setCurrentPathRouth('/'); setShow(false) }} >
                          <a className={` ${currentPath === "/" ? "active" : ""}`}>
                            Home
                          </a>
                        </li>
                        <li onClick={() => { setDeleteUser(true); setCurrentPathRouth('/shop'); setShow(false) }} >
                          <a className={` ${currentPath === "/shop" ? "active" : ""}`}>
                            Shop
                          </a>
                        </li>
                        <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/ticket'); setShow(false) }}>
                          <a className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                            Ticket
                          </a>
                        </li>
                        <li onClick={() => { setDeleteUser(true); setCurrentPathRouth('/about'); setShow(false) }} >
                          <a className={` ${currentPath === "/about" ? "active" : ""}`}>
                            About Us
                          </a>
                        </li>
                      </>
                      :
                      <>
                        <li onClick={() => { setShow(false) }}>
                          <Link to="/" className={` ${currentPath === "/" ? "active" : ""}`}>
                            Home
                          </Link>
                        </li>
                        <li onClick={() => { setShow(false) }}>
                          <Link to="/shop" className={` ${currentPath === "/shop" ? "active" : ""}`}>
                            Shop
                          </Link>
                        </li>
                        <li onClick={() => { setShow(false) }}>
                          <Link to="/ticket" className={` ${currentPath === "/ticket" ? "active" : ""}`}>
                          Ticket
                          </Link>
                        </li>
                        <li onClick={() => { setShow(false) }}>
                          <Link to="/about" className={` ${currentPath === "/about" ? "active" : ""}`}>
                            About Us
                          </Link>
                        </li>
                      </>
                    )}

                  {userId === null ? null :
                    (
                      role === 'member' ?
                        (currentPath.includes('selectmaxpack') ||
                          currentPath.includes('member-shop') ||
                          currentPath.includes('member-cart') ||
                          currentPath.includes('joinasamember') ||
                          packageId ?
                          <li onClick={() => { setShowRemove(true); setCurrentPathRouth('/dashboard-member'); setShow(false) }}>
                            <a className={` ${currentPath === "/dashboard-member" ? "active" : ""}`}>
                              Dashboard
                            </a>
                          </li>
                          :
                          <li>
                            <Link to="/dashboard-member" className={` ${currentPath === "/dashboard-member" ? "active" : ""}`}>
                              Dashboard
                            </Link>
                          </li>
                        )
                        :
                        <li>
                          <Link to="/dashboard" className={` ${currentPath === "/dashboard" ? "active" : ""}`}>
                            Dashboard
                          </Link>
                        </li>
                    )
                  }
                  <li>
                    {userId === null ? (
                      <div className="buttons">
                        {cartCount > 0 ?
                          <button onClick={() => { setShowRemove(true); setCurrentPathRouth('/Joinus'); setShow(false) }} className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                          :
                          <Link to="/Joinus" onClick={() => { setShow(false) }}>
                            <button className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                          </Link>
                        }
                        <Link to="/login" onClick={() => { setShow(false) }}>
                          <button className={`header-login-btn ${currentPath === "signup" ? "active-text" : ""}`}>LOG IN</button>
                        </Link>
                      </div>
                    ) : (
                      paymentStatus === "0" ?
                        <div className="buttons">
                          <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/Joinus'); setShow(false) }}>
                            <button className={`header-join-btn ${currentPath === "joinus" ? "active-text" : ""}`}>JOIN US</button>
                          </a>
                          <a onClick={() => { setDeleteUser(true); setCurrentPathRouth('/login'); setShow(false) }}>
                            <button className={`header-login-btn ${currentPath === "signup" ? "active-text" : ""}`}>LOG IN</button>
                          </a>
                        </div>
                        :
                        <DropdownButton
                          className={` ${currentPath === "joinus" ? "active-text" : ""} btn-danger joinButton1`}
                          size="lg" id="dropdown-basic-button" title={firstName ? firstName : "JOIN US"}>
                          <Dropdown.Item className="joinButton1-item" onClick={signOut}>
                            Log out
                          </Dropdown.Item>
                        </DropdownButton>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header >

      <Modal show={showRemove} onHide={() => { setShowRemove(false) }} className="clear-cart-modal">
        <div className="clear-cart-modal-wrap">
          <span onClick={() => { setShowRemove(false) }} className="cloase">X</span>
          <p className="p1">Are you sure?</p>
          <p className="p2">Your cart items will be removed.</p>
          <div className="buttons">
            {userId != '' && userId != undefined && userId != null ?
              <button onClick={() => { handleRemoveCartUser() }}>Yes</button>
              :
              <button onClick={() => { handleRemoveCart() }}>Yes</button>
            }
            <button onClick={() => { setShowRemove(false) }}>No</button>
          </div>
        </div>
      </Modal>
      <Modal show={deleteUser} onHide={() => { setDeleteUser(false) }} className="clear-cart-modal">
        <div className="clear-cart-modal-wrap">
          <span onClick={() => { setDeleteUser(false) }} className="cloase">X</span>
          <p className="p1">Are you sure?</p>
          <p className="p2">All data will remove</p>
          <div className="buttons">
            <button onClick={() => { deleteGestUser(currentPathRouth) }}>Yes</button>
            <button onClick={() => { setDeleteUser(false) }}>No</button>
          </div>
        </div>
      </Modal>
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navigation;
