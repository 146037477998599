import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Container, Spinner, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

const Tickets = ({ getCartCount }) => {
    const [tickets, setTickets] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const userId = sessionStorage.getItem("userId");
    const role = sessionStorage.getItem("role");
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const ref = params.get('ref');
    if (ref != null) { localStorage.setItem('ref', ref) }
    const navigate = useNavigate()

    const handleShow = (message) => {
        setErrorMessage(message);
        setShowPopup(true);
    };

    const handleClose = () => setShowPopup(false);

    const generateUserId = () => {
        const randomUserId = Math.floor(Math.random() * 1000000);
        sessionStorage.setItem("randomUserId", randomUserId);
        return randomUserId;
    };

    const randomUserIdGuest = sessionStorage.getItem("randomUserId") || generateUserId();

    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
    };

    const addTicketToCart = async (id) => {
        try {
            const token = sessionStorage.getItem("userToken");
            const response = await axios.post(
                "https://max88backend.testingwebsitelink.com/api/buynow",
                {
                    quantity: 1,
                    ticket_id: id,
                    user_id: randomUserIdGuest,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            navigate('/but-it-now-cart')
        } catch (error) {
            handleShow(error?.response?.data?.message);
        }
    };

    const getTickets = async () => {
        try {
            const token = localStorage.getItem("ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE");

            const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/tickets`,
                { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } });
            setTickets(response.data.tickets);
            setIsLoading(false);
        } catch (error) {
            handleShow(error?.response?.data?.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTickets();
    }, []);

    const bannerRef = useRef(null);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE");
            const response = await axios.get(
                "https://max88backend.testingwebsitelink.com/api/slider",
                { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }
            );
            setData(response.data.sliderImage);
        } catch (error) {
            handleShow(error?.response?.data?.message);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container >
                <div className="shopBannerImg" ref={bannerRef}>
                    <Slider {...settings}>
                        {data.map((banner, index) => (
                            <div key={index}><img style={{ objectFit: "contain" }} src={banner.image} alt="Slide 1" /></div>
                        ))}
                    </Slider>
                </div>
                {isLoading ? (
                    <div className="loader-container text-center loader-container-shop"><Spinner animation="border" role="status"></Spinner></div>) :
                    <>
                        <div className="row shopcategoryDiv mt-3">
                            <div className="col-lg-12 mt-2">
                                <div className="row">
                                    {isLoading ? (
                                        <div className="loader-container text-center"><Spinner animation="border" role="status"></Spinner></div>
                                    ) : (
                                        <>
                                            {tickets.length === 0 ? (<h3>Ticket are not available</h3>) : (
                                                <>
                                                    {tickets.map((item, index) => (
                                                        <div className="col-lg-3 col-md-3 col-12 mb-3" key={index}>
                                                            <div className="categoryProductCardLink">
                                                                <Card className="categoryProductCard">
                                                                    <Link to={`/ticketdetail/${item.id}`}>
                                                                        <div className="categoryProductCardImg">
                                                                            <img style={{ width: '100%' }} src={item?.image?.[0]} alt="Product" />
                                                                        </div>
                                                                    </Link>
                                                                    <Card.Body className="categoryProductCardBody">
                                                                        <div className="card-body-buttom ">
                                                                            <Link to={`/ticketdetail/${item.id}`}>
                                                                                <div>
                                                                                    <h4>{item.title}</h4>
                                                                                    <div>
                                                                                        <p>{item.description.length > 30 ?
                                                                                            <>
                                                                                                {item.description.slice(0, 49)}
                                                                                                <a className="textarrr" href="#">Read More..</a>
                                                                                            </>
                                                                                            :
                                                                                            <>{item.description}</>
                                                                                        }</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="categoryProductCardTextBtmh5">
                                                                                        <h5 style={{ justifyContent: 'center' }}>${item.price}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                            <Button variant="danger" onClick={() => addTicketToCart(item.id)}>BUY IT NOW</Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p className="footer-bottom-limne">These statements have not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease</p>
                    </>
                }
            </Container>

            <Modal show={showPopup} onHide={handleClose} className="error-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="error-button-close"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Tickets;
